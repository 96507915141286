<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/lubricaOilManage',query: {active:'level'} }">润滑油级别</el-breadcrumb-item>
        <el-breadcrumb-item>编辑级别</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="级别" prop="level">
          <el-input v-model="form.level" placeholder="请输入级别"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          <el-button size="small" @click="$router.push({ path: '/lubricaOilManage',query: {active:'level'} })">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        level:[{ required: true, message: '必填项', trigger: 'blur' }],
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$ajax.post('lubeLevelEdit', this.form).then(res => {
            this.loading = false
            this.$message.success('成功')
            this.$router.push({ path: '/lubricaOilManage',query: {active:'level'} })
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push({ path: '/lubricaOilManage',query: {active:'level'} })
      return;
    } 
    sessionStorage.setItem("detail", true);
    this.form = this.$route.params.row
  }
}
</script>

<style lang="less" scoped>
.mains {
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
</style>